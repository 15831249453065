var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hello flex justify-center"},[_c('div',{staticClass:"lg:w-1/2 w-full flex flex-col"},[_c('button',{staticClass:"text-white p-2 flex flex justify-center items-center",on:{"click":_vm.play}},[_c('svg',{staticClass:"animate-pulse fill-current text-black dark:text-white",attrs:{"width":"60px","height":"60px","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2 14.9588L2 9.04123C2 8.46617 2.44772 8 3 8H6.58579C6.851 8 7.10536 7.8903 7.29289 7.69503L10.2929 4.30706C10.9229 3.65112 12 4.11568 12 5.04332V18.9567C12 19.8908 10.91 20.3524 10.2839 19.6834L7.29437 16.3145C7.10615 16.1134 6.84791 16 6.57824 16H3C2.44772 16 2 15.5338 2 14.9588Z","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M16 8.5C17.3333 10.2778 17.3333 13.7222 16 15.5","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M19 5C22.9879 8.80835 23.0121 15.2171 19 19","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{ref:"Guesses",staticClass:"overflow-y-auto overflow-x-visable flex flex-col gap-4 py-6"},_vm._l((_vm.guesses),function(guess){return _c('div',{key:guess,ref:guess,refInFor:true,staticClass:"h-30 flex justify-center gap-4"},_vm._l((_vm.noteNos),function(noteno){return _c('div',{key:noteno,staticClass:"\n            rounded-lg\n            p-5\n            border\n            font-bold\n            h-24\n            w-24\n            text-4xl\n            flex\n            justify-center\n            items-center\n            text-black\n          ",class:_vm.prevGuesses[guess] == null
              ? 'bg-white'
              : _vm.prevGuesses[guess]['green'][noteno]
              ? 'bg-green-500'
              : _vm.prevGuesses[guess]['yellow'][noteno]
              ? 'bg-yellow-500'
              : 'bg-gray-500'},[_vm._v(" "+_vm._s(_vm.currentGuesses[guess] ? _vm.currentGuesses[guess][noteno] : "")+" ")])}),0)}),0),_c('div',{staticClass:"flex justify-end gap-4 p-2"},[_c('button',{staticClass:"bg-red-500 p-5 rounded-lg",on:{"click":_vm.deleteLast}},[_c('svg',{attrs:{"width":"24px","height":"24px","viewBox":"0 0 24 24","version":"1.2","baseProfile":"tiny","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M19.5 5h-10c-1.266 0-2.834.807-3.57 1.837l-2.61 3.653-1.199 1.679c-.121.175-.122.492.003.664l1.188 1.664 2.619 3.667c.735 1.029 2.302 1.836 3.569 1.836h10c1.379 0 2.5-1.122 2.5-2.5v-10c0-1.378-1.121-2.5-2.5-2.5zm-2.293 9.793c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2.293-2.293-2.293 2.293c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023 0-1.414l2.293-2.293-2.293-2.293c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l2.293 2.293 2.293-2.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-2.293 2.293 2.293 2.293z"}})])]),_c('button',{staticClass:"p-5 rounded-lg",class:_vm.isReady ? 'bg-green-500' : 'bg-gray-500',attrs:{"disabled":!_vm.isReady},on:{"click":_vm.submit}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"}})])])]),_c('div',{staticClass:"h-full rounded-lg",attrs:{"id":"piano"}},[_c('div',{staticClass:"rounded-lg",attrs:{"id":"piano-container"}},[_c('li',[_c('button',{staticClass:"key white",class:_vm.greenNotes.includes('C')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('C')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('C')
                ? 'bg-gray-500'
                : 'bg-white',on:{"click":function($event){return _vm.selectNote('C')}}}),_c('button',{staticClass:"key black",class:_vm.greenNotes.includes('C#')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('C#')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('C#')
                ? 'bg-gray-500'
                : 'bg-black',on:{"click":function($event){return _vm.selectNote('C#')}}})]),_c('li',[_c('button',{staticClass:"key white",class:_vm.greenNotes.includes('D')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('D')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('D')
                ? 'bg-gray-500'
                : 'bg-white',on:{"click":function($event){return _vm.selectNote('D')}}}),_c('button',{staticClass:"key black",class:_vm.greenNotes.includes('D#')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('D#')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('D#')
                ? 'bg-gray-500'
                : 'bg-black',on:{"click":function($event){return _vm.selectNote('D#')}}})]),_c('li',[_c('button',{staticClass:"key white",class:_vm.greenNotes.includes('E')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('E')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('E')
                ? 'bg-gray-500'
                : 'bg-white',on:{"click":function($event){return _vm.selectNote('E')}}})]),_c('li',[_c('button',{staticClass:"key white",class:_vm.greenNotes.includes('F')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('F')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('F')
                ? 'bg-gray-500'
                : 'bg-white',on:{"click":function($event){return _vm.selectNote('F')}}}),_c('button',{staticClass:"key black",class:_vm.greenNotes.includes('F#')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('F#')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('F#')
                ? 'bg-gray-500'
                : 'bg-black',on:{"click":function($event){return _vm.selectNote('F#')}}})]),_c('li',[_c('button',{staticClass:"key white",class:_vm.greenNotes.includes('G')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('G')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('G')
                ? 'bg-gray-500'
                : 'bg-white',on:{"click":function($event){return _vm.selectNote('G')}}}),_c('button',{staticClass:"key black",class:_vm.greenNotes.includes('G#')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('G#')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('G#')
                ? 'bg-gray-500'
                : 'bg-black',on:{"click":function($event){return _vm.selectNote('G#')}}})]),_c('li',[_c('button',{staticClass:"key white",class:_vm.greenNotes.includes('A')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('A')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('A')
                ? 'bg-gray-500'
                : 'bg-white',on:{"click":function($event){return _vm.selectNote('A')}}}),_c('button',{staticClass:"key black",class:_vm.greenNotes.includes('A#')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('A#')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('A#')
                ? 'bg-gray-500'
                : 'bg-black',on:{"click":function($event){return _vm.selectNote('A#')}}})]),_c('li',[_c('button',{staticClass:"key white",class:_vm.greenNotes.includes('B')
                ? 'bg-green-500'
                : _vm.yellowNotes.includes('B')
                ? 'bg-yellow-500'
                : _vm.grayNotes.includes('B')
                ? 'bg-gray-500'
                : 'bg-white',on:{"click":function($event){return _vm.selectNote('B')}}})])])]),(_vm.showing)?_c('div',{staticClass:"z-30"},[_c('WinModal',{staticClass:"z-40",attrs:{"values":_vm.winvalues},on:{"close":function($event){_vm.showing = false;
          _vm.reset();},"play":function($event){return _vm.play()}}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }