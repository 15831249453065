<template>
  <div class="hello flex justify-center">
    <div class="lg:w-1/2 w-full flex flex-col">
      <button
        class="text-white p-2 flex flex justify-center items-center"
        v-on:click="play"
      >
        <svg
          class="animate-pulse fill-current text-black dark:text-white"
          width="60px"
          height="60px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 14.9588L2 9.04123C2 8.46617 2.44772 8 3 8H6.58579C6.851 8 7.10536 7.8903 7.29289 7.69503L10.2929 4.30706C10.9229 3.65112 12 4.11568 12 5.04332V18.9567C12 19.8908 10.91 20.3524 10.2839 19.6834L7.29437 16.3145C7.10615 16.1134 6.84791 16 6.57824 16H3C2.44772 16 2 15.5338 2 14.9588Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 8.5C17.3333 10.2778 17.3333 13.7222 16 15.5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19 5C22.9879 8.80835 23.0121 15.2171 19 19"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div class="overflow-y-auto overflow-x-visable flex flex-col gap-4 py-6" ref="Guesses">
        <div
          class="h-30 flex justify-center gap-4"
          v-for="guess in guesses"
          :key="guess"
          :ref="guess"
        >
          <div
            class="
              rounded-lg
              p-5
              border
              font-bold
              h-24
              w-24
              text-4xl
              flex
              justify-center
              items-center
              text-black
            "
            :class="
              prevGuesses[guess] == null
                ? 'bg-white'
                : prevGuesses[guess]['green'][noteno]
                ? 'bg-green-500'
                : prevGuesses[guess]['yellow'][noteno]
                ? 'bg-yellow-500'
                : 'bg-gray-500'
            "
            v-for="noteno in noteNos"
            :key="noteno"
          >
            {{ currentGuesses[guess] ? currentGuesses[guess][noteno] : "" }}
          </div>
        </div>
      </div>


      <div class="flex justify-end gap-4 p-2">
        <button class="bg-red-500 p-5 rounded-lg" v-on:click="deleteLast">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 5h-10c-1.266 0-2.834.807-3.57 1.837l-2.61 3.653-1.199 1.679c-.121.175-.122.492.003.664l1.188 1.664 2.619 3.667c.735 1.029 2.302 1.836 3.569 1.836h10c1.379 0 2.5-1.122 2.5-2.5v-10c0-1.378-1.121-2.5-2.5-2.5zm-2.293 9.793c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2.293-2.293-2.293 2.293c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023 0-1.414l2.293-2.293-2.293-2.293c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l2.293 2.293 2.293-2.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-2.293 2.293 2.293 2.293z"/></svg>
        </button>
        <button
          v-on:click="submit"
          class="p-5 rounded-lg"
          :class="isReady ? 'bg-green-500' : 'bg-gray-500'"
          :disabled="!isReady"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
        </button>
      </div>
      

      <div id="piano" class="h-full rounded-lg">
        <div id="piano-container" class="rounded-lg">
          <li>
            <button
              class="key white"
              :class="
                greenNotes.includes('C')
                  ? 'bg-green-500'
                  : yellowNotes.includes('C')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('C')
                  ? 'bg-gray-500'
                  : 'bg-white'
              "
              v-on:click="selectNote('C')"
            ></button>
            <button
              class="key black"
              :class="
                greenNotes.includes('C#')
                  ? 'bg-green-500'
                  : yellowNotes.includes('C#')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('C#')
                  ? 'bg-gray-500'
                  : 'bg-black'
              "
              v-on:click="selectNote('C#')"
            ></button>
          </li>
          <li>
            <button
              class="key white"
              :class="
                greenNotes.includes('D')
                  ? 'bg-green-500'
                  : yellowNotes.includes('D')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('D')
                  ? 'bg-gray-500'
                  : 'bg-white'
              "
              v-on:click="selectNote('D')"
            ></button>
            <button
              class="key black"
              :class="
                greenNotes.includes('D#')
                  ? 'bg-green-500'
                  : yellowNotes.includes('D#')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('D#')
                  ? 'bg-gray-500'
                  : 'bg-black'
              "
              v-on:click="selectNote('D#')"
            ></button>
          </li>
          <li>
            <button
              class="key white"
              :class="
                greenNotes.includes('E')
                  ? 'bg-green-500'
                  : yellowNotes.includes('E')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('E')
                  ? 'bg-gray-500'
                  : 'bg-white'
              "
              v-on:click="selectNote('E')"
            ></button>
          </li>
          <li>
            <button
              class="key white"
              :class="
                greenNotes.includes('F')
                  ? 'bg-green-500'
                  : yellowNotes.includes('F')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('F')
                  ? 'bg-gray-500'
                  : 'bg-white'
              "
              v-on:click="selectNote('F')"
            ></button>
            <button
              class="key black"
              :class="
                greenNotes.includes('F#')
                  ? 'bg-green-500'
                  : yellowNotes.includes('F#')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('F#')
                  ? 'bg-gray-500'
                  : 'bg-black'
              "
              v-on:click="selectNote('F#')"
            ></button>
          </li>
          <li>
            <button
              class="key white"
              :class="
                greenNotes.includes('G')
                  ? 'bg-green-500'
                  : yellowNotes.includes('G')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('G')
                  ? 'bg-gray-500'
                  : 'bg-white'
              "
              v-on:click="selectNote('G')"
            ></button>
            <button
              class="key black"
              :class="
                greenNotes.includes('G#')
                  ? 'bg-green-500'
                  : yellowNotes.includes('G#')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('G#')
                  ? 'bg-gray-500'
                  : 'bg-black'
              "
              v-on:click="selectNote('G#')"
            ></button>
          </li>
          <li>
            <button
              class="key white"
              :class="
                greenNotes.includes('A')
                  ? 'bg-green-500'
                  : yellowNotes.includes('A')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('A')
                  ? 'bg-gray-500'
                  : 'bg-white'
              "
              v-on:click="selectNote('A')"
            ></button>
            <button
              class="key black"
              :class="
                greenNotes.includes('A#')
                  ? 'bg-green-500'
                  : yellowNotes.includes('A#')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('A#')
                  ? 'bg-gray-500'
                  : 'bg-black'
              "
              v-on:click="selectNote('A#')"
            ></button>
          </li>
          <li>
            <button
              class="key white"
              :class="
                greenNotes.includes('B')
                  ? 'bg-green-500'
                  : yellowNotes.includes('B')
                  ? 'bg-yellow-500'
                  : grayNotes.includes('B')
                  ? 'bg-gray-500'
                  : 'bg-white'
              "
              v-on:click="selectNote('B')"
            ></button>
          </li>
        </div>
      </div>

      <div v-if="showing" class="z-30">
        <WinModal
          class="z-40"
          :values="winvalues"
          @close="
            showing = false;
            reset();
          "
          @play="play()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ChordType, Chord, Note } from "@tonaljs/tonal";
var Soundfont = require("soundfont-player");
import WinModal from "./WinModal.vue";

export default {
  name: "HelloWorld",
  components: {
    WinModal,
  },
  data() {
    return {
      player: null,
      ac: new AudioContext(),
      chord: "",
      notes: [],
      guesses: [...Array(4).keys()],
      selected: [0, 0],
      currentGuesses: {},
      prevGuesses: {},
      greenNotes: [],
      yellowNotes: [],
      grayNotes: [],
      winvalues: {},
      showing: false,
    };
  },
  props: {
    msg: String,
  },
  computed: {
    settings() {
      return this.$root.state.settings;
    },
    noteNos() {
      return [...Array(this.notes.length).keys()];
    },
    isReady() {
      //      return
      if (
        this.selected[1] == this.settings.chordlength - 1 &&
        this.settings.chordlength - 1 in
          this.currentGuesses[this.selected[0]] &&
        this.currentGuesses[this.selected[0]][this.selected[1]] != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    play() {
      var dt = 0.05;
      var sched = [];
      for (var i = 0; i < this.notes.length; i++) {
        sched.push({
          time: i * dt * this.settings.arpeggio,
          note: this.notes[i],
        });
      }
      this.player.schedule(this.ac.currentTime, sched);
    },
    generateChord() {
      //console.log(this.$root.state.settings)
      var chordTypes = Object.keys(this.settings.chordType).filter(
        (get) => this.settings.chordType[get] == true
      );

      var typeList = ChordType.all()
        .filter((item) => item.intervals.length == this.settings.chordlength)
        .filter((item) => chordTypes.includes(item.quality));

      var chord = typeList[Math.floor(Math.random() * typeList.length)];
      var chordName = chord.aliases[0];
      var allnotes = [
        "C",
        "C#",
        "D",
        "D#",
        "E",
        "F",
        "F#",
        "G",
        "G#",
        "A",
        "A#",
        "B",
        "C",
      ];
      var rootNote =
        this.settings.rootNote == "random"
          ? allnotes[Math.floor(Math.random() * allnotes.length)]
          : this.settings.rootNote;
      var chordSymbol = Chord.getChord(
        chordName,
        rootNote + this.settings.octave
      ).symbol;
      var notes = Chord.getChord(
        chordName,
        rootNote + this.settings.octave
      ).notes;

      this.notes = notes;
      this.chord = chordSymbol;
    },
    selectNote(note) {
      var cguess = this.selected[0];
      var cnoteno = this.selected[1];

      this.$set(this.currentGuesses, cguess, {
        ...this.currentGuesses[cguess],
      });
      this.$set(this.currentGuesses[cguess], cnoteno, note);
      if (cnoteno != this.settings.chordlength - 1) {
        this.$set(this.selected, 1, this.selected[1] + 1);
      }
      console.log(this.isReady);
      //this.selected = [this.selected[0], note]
    },
    deleteLast() {
      //   this.$set(this.selected, 0, this.selected[0]-1)
      //   this.$set(this.selected, 1, this.settings.chordlength-1)
      var cguess = this.selected[0];
      var cnoteno = this.selected[1];

      if (
        cnoteno != this.settings.chordlength - 1 ||
        this.currentGuesses[cguess][cnoteno] == "" ||
        this.currentGuesses[cguess][cnoteno] == null
      ) {
        // move back one note and clear
        if (cnoteno > 0) {
          this.$set(this.selected, 1, this.selected[1] - 1);
        }
        cguess = this.selected[0];
        cnoteno = this.selected[1];
        this.$set(this.currentGuesses, cguess, {
          ...this.currentGuesses[cguess],
        });
        this.$set(this.currentGuesses[cguess], cnoteno, "");
      } else {
        this.$set(this.currentGuesses, cguess, {
          ...this.currentGuesses[cguess],
        });
        this.$set(this.currentGuesses[cguess], cnoteno, "");
      }
      
    },
    submit() {
      var guess = Object.values(this.currentGuesses[this.selected[0]]);
      var correctNotes = this.notes.map((note) =>
        Note.simplify(Note.pitchClass(note))
      );
      correctNotes = correctNotes.map((note) =>
        note.includes("b") ? Note.enharmonic(note) : note
      );
      //var green : compares guess to correct notes, equals to a boolean array
      var green = guess.map((note, idx) => correctNotes[idx] == note);
      var yellow = guess.map(
        (note, idx) => correctNotes.includes(note) && !green[idx]
      );
      this.$set(this.prevGuesses, this.selected[0], {
        green: green,
        yellow: yellow,
        guess: guess,
      });

      this.greenNotes.push(
        ...guess
          .map((note, idx) => (green[idx] ? note : null))
          .filter((note) => note != null)
      );
      this.yellowNotes.push(
        ...guess
          .map((note, idx) => (yellow[idx] ? note : null))
          .filter((note) => note != null)
      );
      this.grayNotes.push(
        ...guess
          .map((note, idx) => (!green[idx] && !yellow[idx] ? note : null))
          .filter((note) => note != null)
      );

      if (this.selected[0] == this.guesses.length - 1) {
        this.end();
      }

      if (this.selected[0] < this.guesses.length - 1) {
        this.$set(this.selected, 1, 0);
        this.$set(this.selected, 0, this.selected[0] + 1);
      }

      if (green.every((item) => item == true)) {
        this.end();
      }

      this.goto(this.selected[0]);
    },
    reset() {
      this.selected = [0, 0];
      this.currentGuesses = {};
      this.prevGuesses = {};
      this.greenNotes = [];
      this.yellowNotes = [];
      this.grayNotes = [];
      this.generateChord();
      this.play();
      this.goto(0)
    },
    end() {
      var win = this.prevGuesses[
        Object.keys(this.prevGuesses)[Object.keys(this.prevGuesses).length - 1]
      ].green.every((item) => item == true);
      console.log(win);
      console.log("end");
      this.winvalues = {
        chord: Chord.get(this.chord),
        score: Object.keys(this.currentGuesses).length,
        won: win,
        guesses:this.prevGuesses
      };
      window.scrollTo(0, 0);
      this.showing = "true";
    },
    goto(refName) {
      var div = this.$refs['Guesses'];
      var element = this.$refs[refName][0];

      div.scrollTop = element.offsetTop - div.offsetTop-70;

    }
  },
  mounted() {
    Soundfont.instrument(this.ac, "acoustic_grand_piano", {
      soundfont: "MusyngKite",
    }).then((player) => {
      this.player = player;
    });
    this.generateChord();
  },
  watch: {
    settings: {
      handler: function () {
        this.reset();
      },
      deep: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background: mistyrose;
  font-family: "Great Vibes", cursive;
}

.light {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ff7700;
  float: left;
  margin-right: 10px;
  margin-top: 25px;
}
.text {
  float: left;
  width: 10%;
}
.text h1 {
  font-family: metropolis;
  font-size: 38px;
  color: #eee;
  line-height: 60px;
}
#piano-container {
  width: 100%;
  height: 100%;
  background: #fff;
}
#piano-container li {
  width: 14.28%;
  height: 100%;
  float: left;
  list-style-type: none;
  position: relative;
}
.white {
  width: 100%;
  height: 100%;
  float: left;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}
button:focus {
  outline: none;
}
.white:active {
  box-shadow: -1px 0 15px rgba(0, 0, 0, 0.7) inset;
  -webkit-box-shadow: -1px 0 15px rgba(0, 0, 0, 0.7) inset;
  -moz-box-shadow: -1px 0 15px rgba(0, 0, 0, 0.7) inset;
  -ms-box-shadow: -1px 0 15px rgba(0, 0, 0, 0.7) inset;
}
.black {
  position: absolute;
  border: none;
  z-index: 2;
  width: 70%;
  height: 120px;
  right: -35%;
  box-shadow: 1px 0 #111, 0 2px 0 #111, 0 3px 0 #333, 0 4px 0 #333, 0 5px 0 #333,
    0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 1px 0 #777, 0 2px 0 #777, 0 3px 0 #666, 0 4px 0 #555,
    0 5px 0 #444, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 0 #333, 0 2px 0 #555, 0 3px 0 #555, 0 4px 0 #666,
    0 5px 0 #777, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 1px 0 #333, 0 2px 0 #555, 0 3px 0 #555, 0 4px 0 #666,
    0 5px 0 #777, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}
.black:active {
  box-shadow: 1px 0 #777, 0 2px 0 #777, 0 3px 0 #666, 0 4px 0 #555;
  -webkit-box-shadow: 1px 0 #555, 0 1px 0 #555, 0 1px 0 #444, 0 2px 0 #333;
  -moz-box-shadow: 1px 0 #111, 0 2px 0 #222, 0 3px 0 #222;
  -ms-box-shadow: 1px 0 #111, 0 2px 0 #222, 0 3px 0 #222;
}
</style>
