<template>
  <div
    class="
      winmodal
      fixed
      inset-0
      w-full
      h-screen
      flex
      items-center
      justify-center
      bg-semi
    "
  >
    <div
      class="
        relative
        lg:w-1/2
        w-full
        max-w-2xl
        bg-white
        dark:bg-gray-800
        shadow-lg
        rounded-lg
        p-8
        opacity-100
        z-40
        flex flex-col
        gap-4
      "
    >
      <button
        aria-label="close"
        class="
          absolute
          top-0
          right-0
          text-xl text-gray-500
          dark:text-white
          my-2
          mx-4
        "
        @click.prevent="close"
      >
        x
      </button>
      <p
        class="font-bold text-3xl"
        :class="values.won ? 'text-green-500' : 'text-red-500'"
      >
        {{ values.won ? "Correct!" : "Wrong!" }}
      </p>

      <p class="font-bold text-3xl text-gray-700 dark:text-gray-100">
        Guesses:
        <span class="text-black dark:text-white">{{
          values.won ? values.score : "X"
        }}</span>
      </p>

      <p class="font-bold text-3xl text-gray-700">Chord:</p>
      <div class="flex justify-evenly py-5">
        <div></div>
        <p class="italic font-bold text-4xl">{{ values.chord.symbol }}</p>
        <button
          class="bg-primary rounded-lg p-2 font-bold text-white"
          @click.prevent="play"
        >
          Play
        </button>
      </div>
      <p class="font-bold text-3xl text-gray-700">Notes:</p>
      <div class="flex italic font-bold text-2xl justify-center">
        <div v-for="(note, index) in values.chord.notes" :key="note">
          {{ note }}<span v-if="index + 1 < values.chord.notes.length">, </span>
        </div>
      </div>

      <p class="font-bold text-3xl text-gray-700">Quality:</p>
      <p class="flex italic font-bold text-2xl justify-center">
        {{ values.chord.quality }}
      </p>

      <p class="font-bold text-3xl text-gray-700">Aliases:</p>
      <div class="flex italic font-bold text-2xl justify-center">
        <div v-for="(alias, index) in values.chord.aliases" :key="alias">
          {{ alias
          }}<span v-if="index + 1 < values.chord.aliases.length">, </span>
        </div>
      </div>

      <button
        class="bg-green-500 rounded-lg p-2 font-bold text-white"
        @click.prevent="close"
      >
        Continue
      </button>
      <button
        class="bg-primary rounded-lg p-2 font-bold text-white"
        @click.prevent="share"
      >
        {{ copied ? "Copied!" : "Share" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "WinModal",
  data() {
    return {
      copied: false,
    };
  },
  props: {
    values: Object,
  },
  computed: {},
  methods: {
    close() {
      this.copied = false;
      this.$emit("close");
    },
    play() {
      this.$emit("play");
    },
    share() {
      var greens = Object.keys(this.values.guesses).map((key) => {
        return this.values.guesses[key].green;
      });
      var yellows = Object.keys(this.values.guesses).map((key) => {
        return this.values.guesses[key].yellow;
      });
      console.log(greens);
      console.log(yellows);

      var shareText = "";

      if (this.values.won) {
        shareText +=
          this.values.chord.symbol + ": " + this.values.score + "/4\n";
      } else {
        shareText += this.values.chord.symbol + ": " + "X/4\n";
      }

      shareText += "\n";
      var x = greens.length;
      var y = greens[0].length;

      for (var i = 0; i < x; i++) {
        for (var j = 0; j < y; j++) {
          if (greens[i][j]) {
            shareText += "🟩";
          } else if (yellows[i][j]) {
            shareText += "🟨";
          } else {
            shareText += "⬛️";
          }
        }
        shareText += "\n";
      }

      if (navigator.share) {
        navigator
          .share({
            title: "Chordle",
            text: shareText,
            url: "https://chordle.xyz",
          })
          .then(() => {
            console.log("Thanks for sharing!");
          })
          .catch(console.error);
      } else {
        var dummy = document.createElement("textarea");
        // to avoid breaking orgain page when copying more words
        // cant copy when adding below this code
        // dummy.style.display = 'none'
        document.body.appendChild(dummy);
        //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
        dummy.value = shareText;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        this.copied = true;
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
