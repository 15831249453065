import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'

Vue.config.productionTip = false

var store = {
  debug: true,
  state: {
    settings:{
      rootNote: "random",
      chordType: {
        major: true,
        minor: false,
        sus: false,
        augmented: false,
        diminished: false,
      },
      arpeggio: 0,
      octave: 4,
      chordlength: 3,
      darkMode: false
    }
  },
  setSettings (newValue) {
    if (this.debug) console.log('setMessageAction triggered with', newValue)
    this.state.settings = newValue
  }
}

new Vue({
  render: h => h(App),
  data: {
    state: store.state
  },
  methods: {
    setSettings: store.setSettings
  }
}).$mount('#app')
