<template>
  <div class="settingsbar dark:bg-gray-800">
    <header class="p-3 flex justify-around border-b-2 border-gray-400">
      <h1 class="font-bold text-xl">Settings</h1>

      <div class="flex flex-wrap gap-2 content-center">
        <svg class="fill-current dark:text-white" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <path d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,20 L12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 Z" id="🎨-Color"></path>
        </svg>
        
        <!-- Rounded switch -->
        <label class="switch">
          <input type="checkbox" v-model="settings.darkMode">
          <span class="slider round"></span>
        </label>
      </div>

    </header>

    <div class="flex flex-col p-5 space-y-5 font-bold">

      <p>Root Note</p>
      <select name="" id="" class="bg-primary font-bold text-white p-3 rounded-lg" v-model="settings.rootNote">
        <option value="random">Perfect Pitch (Random)</option>
        <option value="C">C</option>
        <option value="C#">C#</option>
        <option value="D">D</option>
        <option value="D#">D#</option>
        <option value="E">E</option>
        <option value="F">F</option>
        <option value="F#">F#</option>
        <option value="G">G</option>
        <option value="G#">G#</option>
        <option value="A">A</option>
        <option value="A#">A#</option>
        <option value="B">B</option>
      </select>

      <p>Chord Type</p>
      <div class="flex flex-wrap justify-center gap-3">
        <button v-on:click="updateChordType('Major')" class="rounded-lg p-3 text-white font-bold" :class="[settings.chordType['Major'] ? 'bg-primary' : 'bg-gray-500']">Major</button>
        <button v-on:click="updateChordType('Minor')" class="rounded-lg p-3 text-white font-bold" :class="[settings.chordType['Minor'] ? 'bg-primary' : 'bg-gray-500']">Minor</button>
        <button v-on:click="updateChordType('Unknown')" class="rounded-lg p-3 text-white font-bold" :class="[settings.chordType['Unknown'] ? 'bg-primary' : 'bg-gray-500']">Sus</button>
        <button v-on:click="updateChordType('Augmented')" class="rounded-lg p-3 text-white font-bold" :class="[settings.chordType['Augmented'] ? 'bg-primary' : 'bg-gray-500']">Augmented</button>
        <button v-on:click="updateChordType('Diminished')" class="rounded-lg p-3 text-white font-bold" :class="[settings.chordType['Diminished'] ? 'bg-primary' : 'bg-gray-500']">Diminished</button>
      </div>
      
      <p>Chord Length <span class="font-normal italic dark:text-gray-100 text-gray-500">(no. of notes in chord)</span></p>
      <p class="flex justify-center text-xl font-bold"> {{ settings.chordlength }}</p>
      <input class="dark:bg-gray-800" type="range" id="chordlength" name="chordlength" min="2" max="7" step="1" value="4" v-model="settings.chordlength">

      <p>Arpeggio</p>
      <p class="flex justify-center text-xl font-bold">{{arpeggioSpeeds[settings.arpeggio]}}</p>
      <input class="dark:bg-gray-800" type="range" id="points" name="points" min="0" max="3" step="1" value="0" v-model="settings.arpeggio"> 

      <p>Octave</p>
      <p class="flex justify-center text-xl font-bold"> {{ settings.octave }}</p>
      <input class="dark:bg-gray-800" type="range" id="octave" name="octave" min="-1" max="9" step="1" value="4" v-model="settings.octave">
    </div>
  </div>
</template>

<script>

export default {
  name: 'SettingsBar',
  data() {
    return{
      settings:{
        rootNote: "random",
        chordType: {
          'Major': true,
          'Minor': false,
          'Unknown': false,
          'Augmented': false,
          'Diminished': false,
        },
        arpeggio: 0,
        octave: 4,
        chordlength: 3,
        darkMode: false
      },
      arpeggioSpeeds: ["None", "Fast", "Medium", "Slow"]
    }
  },
  props: {
  },
  methods: {
    updateChordType(type) {
      this.settings.chordType[type] = !this.settings.chordType[type]
    }
  },
  mounted() {
    var settings = localStorage.getItem('settings')
    if (settings) {
      this.$root.setSettings(settings)
      this.settings = JSON.parse(settings)
    } else {
      localStorage.setItem('settings', JSON.stringify(this.settings))
    }
  },
  watch: {
    settings: {
      handler: function(newSettings) {
        console.log("saving")
        this.$root.setSettings(newSettings)
        localStorage.setItem('settings', JSON.stringify(newSettings))
      },
      deep: true
    },
    'settings.darkMode': {
      handler: function(darkMode) {
        if (darkMode) {
          document.body.classList.add('dark')
        } else {
          document.body.classList.remove('dark')
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3.5px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #000000;
}

input:focus + .slider {
  box-shadow: 0 0 10px #3d71a5;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} 

input[type=range] {
  height: 37px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 5px #000000;
  background: #E0591B;
  border-radius: 38px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 8px #000000;
  border: 0px solid #000000;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -12.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #E0591B;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 5px #000000;
  background: #E0591B;
  border-radius: 38px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 8px #000000;
  border: 0px solid #000000;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #E0591B;
  border: 0px solid #000000;
  border-radius: 76px;
  box-shadow: 1px 1px 5px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #E0591B;
  border: 0px solid #000000;
  border-radius: 76px;
  box-shadow: 1px 1px 5px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 8px #000000;
  border: 0px solid #000000;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #E0591B;
}
input[type=range]:focus::-ms-fill-upper {
  background: #E0591B;
}


</style>
